import { useNavigate, useParams } from "react-router-dom";
import { useAppointmentStore } from "../store/appointment";
import { useEffect, useState } from "react";
import { Image, Container, Col, Card, Alert, Button } from 'react-bootstrap';
import { bookingLabels, createAccLabels } from "../store/language";
import { useUserInfoStore } from "../store/auth";
import './[id].css';

export default function Appointments() {
    const { date } = useParams<{ date: string }>();
    const { loadAppointmentItemByDate, appointment } = useAppointmentStore()
    const { language } = useUserInfoStore()

    const [labels, setLabels] = useState(createAccLabels['en'])
    const [labels2, setLabels2] = useState(bookingLabels['en'])

    useEffect(() => {
        loadAppointmentItemByDate(date as string)
        setLabels(createAccLabels[language])
        setLabels2(bookingLabels[language])
        console.log('[id]Page')
    }, [language, loadAppointmentItemByDate, setLabels, setLabels2, date])

    const sortedAppointments = [...appointment].sort((a, b) => {
        const timeA = a.bookingTime;
        const timeB = b.bookingTime;
        return timeA.localeCompare(timeB);
    });

    const navigate = useNavigate()

    if (!labels || !labels2) {
        return (<div>Loading...</div>);
    }

    return (
        <Container>
                <Col lg={8} className="AppointmentIdCss">
                    <h2 className="text-center mb-4">{date}</h2>
                    {sortedAppointments.length > 0 ? sortedAppointments.map((item, index) =>
                        <Card key={index}>
                            <Card.Body>
                                <Container className="dataWithImage">
                                <Container className="data-container">
                                    <Card.Title>{labels.name}: {item.created_by}</Card.Title>
                                    <Card.Text>{labels2.bookingDate}: {item.bookingDate} - {item.bookingTime}</Card.Text>
                                    <Card.Text>{labels.phone}: {item.phone}</Card.Text>
                                    <Card.Text>{labels2.remark}: {item.remark}</Card.Text>
                                </Container>
                                <Container className="image-container">
                                    {item.photo ? (
                                        <Image src={item.photo} thumbnail />
                                    ) : (
                                        <Image src={`${process.env.PUBLIC_URL}/noImage.jpg`} thumbnail />
                                    )}
                                </Container>
                                </Container>
                            </Card.Body>
                        </Card>
                    ) : (
                        <Alert variant="info" className="text-center">No Booking In This Day</Alert>
                    )}
                    <Button className="backButton" onClick={() => {navigate(-1)}}>Back</Button>
                </Col>
        </Container>
    )
}

