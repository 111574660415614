import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useNavigate } from 'react-router-dom';
import { useAppointmentStore } from "../store/appointment";
import { useUserInfoStore } from "../store/auth";
import 'react-calendar/dist/Calendar.css';
import './AppointmentPage.css'

export default function MainPage() {
    const [date, setDate] = useState<Date | null>(null)
    const { companyAppointment, loadAppointmentItemByCompany } = useAppointmentStore()
    const { company } = useUserInfoStore()
    
    const navigate = useNavigate();
    console.log(date)
    
    useEffect(() => {
        loadAppointmentItemByCompany(company)
        console.log('AppointmentPage')
    }, [company, loadAppointmentItemByCompany])

    const dataOnDate = async (date: Date) => {
        setDate(date)
        const formattedDate = date.toLocaleDateString('en-CA')
        navigate(`/appointments/${formattedDate}`)
    }

    const tileContent = ({ date, view }: { date: Date, view: string }) => {
        if (view === 'month') {
            const formattedDate = date.toLocaleDateString('en-CA')
            const confirmedItem = companyAppointment.filter(item => item.status === 'Confirmed')
            const hasAppointment = confirmedItem.some(
                item => item.bookingDate === formattedDate
            )
            return hasAppointment? (
                <div className="appointment-indicator"></div>
            ) : null
        }
        return null
    }

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
            <div className="col-lg-8">
                    <Calendar 
                        onClickDay={dataOnDate}
                        tileContent={tileContent}
                        className="shadow p-3 mb-5 bg-body rounded"
                    />
                </div>
            </div>
        </div>
    )
}
