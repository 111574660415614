import { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import emailjs from "emailjs-com";
import "./ContactUsPage.css";
import { contactUsLabels } from "./store/language";
import { useUserInfoStore } from "./store/auth";

export default function ContactUsPage() {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [content, setContent] = useState("");
  const [labels, setLabels] = useState(contactUsLabels["en"]);
  const [languageSelect, setLanguageSelect] = useState("en");
  const [loading, setLoading] = useState(false);
  const { language } = useUserInfoStore();

  useEffect(() => {
    if (language) {
      setLabels(contactUsLabels[language]);
    } else {
      setLabels(contactUsLabels[languageSelect]);
    }
  }, [languageSelect, language]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const templateParams = {
      user_email: process.env.REACT_APP_EMAIL_CONFIG_EMAIL,
      to_name: "Faii",
      from_name: name,
      reply: contact,
      message: content,
    };

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAIL_CONFIG_SERVICE + "",
        process.env.REACT_APP_EMAIL_CONFIG_TEMPLATE + "",
        templateParams,
        process.env.REACT_APP_EMAIL_CONFIG_TOKEN
      );
      setContact("");
      setContent("");
      setName("");
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  if (!labels) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="contact-us-container">
      <h2 className="text-center mt-4">{labels.contactUs}</h2>
      <Form onSubmit={handleSubmit} className="contact-form">
        {!language && (
          <>
            <Form.Label>{labels.language}</Form.Label>
            <Container className="language">
              <Form.Select
                value={language}
                onChange={(e) => setLanguageSelect(e.target.value)}
                className="language-select"
              >
                <option value="en">English</option>
                <option value="zh">中文</option>
                <option value="th">ไทย</option>
              </Form.Select>
            </Container>
          </>
        )}

        <Form.Group controlId="name">
          <Form.Label>{labels.yourName}</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="contact">
          <Form.Label>{labels.yourContact}</Form.Label>
          <Form.Control
            type="text"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="content">
          <Form.Label>{labels.yourContent}</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          className="mt-3 w-100"
          disabled={loading}
        >
          {labels.submit}
        </Button>
      </Form>
    </Container>
  );
}
