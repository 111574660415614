import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { create } from 'zustand'
import { auth, db } from '../firebase'
import { collection, getDocs } from 'firebase/firestore'

interface AuthStore {
    error: string
    login: (email:string, password:string)=>Promise<boolean>
    logout:()=>void
    userEmail: string | null
    uid: string | null
}

export interface userInfoStore {
    name: string
    role: string
    status: string
    phone: string
    created_by: string
    email: string
    company: string
    language: string
    loadUserInfo:()=>void
}

export const useAuthStore = create<AuthStore>((set, get) => ({
    error: "",
    userEmail: null,
    uid: null,
    async login(email, password){
        set({error: ""})
        try{
            await signInWithEmailAndPassword(auth, email, password)
            set({
                userEmail: email,
                uid: auth.currentUser?.uid
            })
            return true
        }catch(error){
            set({error:error+""})
            return false
        }
    },
    async logout(){
        await signOut(auth)
        set({userEmail: null})
        window.location.href = '/';
    },
}))

export const useUserInfoStore = create<userInfoStore>((set, get) => ({
    role: "",
    name: "",
    phone: "",
    status: "",
    hire_date: "",
    created_by: "",
    email: "",
    company: "",
    language: "",
    async loadUserInfo(){
        const ref = await getDocs(collection(db, 'user'))
        const docs = ref.docs
        const user_id = (auth.currentUser?.uid)
        const user = docs.find(doc => doc.id === user_id)?.data()
        if(user){
            const userInfos = {
                name: user.name,
                role: user.role,
                phone: user.phone,
                status: user.status,
                created_by: user.created_by,
                email: user.email,
                company: user.company,
                language: user.language,
            }
            set({...userInfos})
        }
    },
}));