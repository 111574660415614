import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import BookingPage from './BookingPage';
import Appointments from './protected/[id]';
import MainPage from './protected/AppointmentPage';
import { ProtectedRoute } from './store/loginFunction';
import FirstPage from './protected/FirstPage';
import CreateCompanyAc from './create/CreateCompanyAc';
import ContactUsPage from './ContactUsPage';
import NavbarLink from './NavbarLink';
import { Container } from 'react-bootstrap';

export default function App() {

  const Navbar: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    return (
      <div>
        <NavbarLink />
        <Container>
          {children}
        </Container>
      </div>
    );
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path='*' element={<Navbar><Login /></Navbar>} />
          <Route path='/login' element={<Navbar><Login /></Navbar>} />
          <Route path='/createCompany' element={<Navbar><CreateCompanyAc /></Navbar>} />
          <Route path='/contact' element={<Navbar><ContactUsPage /></Navbar>} />
          <Route path='/' element={<Navbar><BookingPage /></Navbar>} />

          <Route path='/first' element={
            <ProtectedRoute>
              <Navbar><FirstPage /></Navbar>
            </ProtectedRoute>
          } />
          <Route path='/main' element={
            <ProtectedRoute>
              <Navbar><MainPage /></Navbar>
            </ProtectedRoute>
          } />
          <Route path='/appointments/:date' element={
            <ProtectedRoute>
              <Navbar><Appointments /></Navbar>
            </ProtectedRoute>
          } />
        </Routes>
      </div>
    </Router>
  );
}
