import { useState } from "react"
import { createAccLabels } from "../store/language"
import { useNavigate } from "react-router-dom";
import { createLoginAcc } from "../firebase";
import { useCreateAcStore } from "../store/create";
import { Form, Button, Container, InputGroup, Modal } from "react-bootstrap";
import "./CreateAc.css";

export default function CreateCompanyAc() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('');
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [company, setCompany] = useState('')
    const [address, setAddress] = useState('')
    const [tel, setTel] = useState('')
    const [language, setLanguage] = useState('en');
    const [loading, setLoading] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const { createCompanyAc } = useCreateAcStore()

    const chooseLabels = createAccLabels[language];
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match");
            setShowErrorModal(true);
            return;
        }

        setLoading(true)
        try {
            const uid = await createLoginAcc(email, password)
            const personalData = {
                created_at: new Date().toLocaleDateString(),
                email: email,
                name: name,
                role: 'CompanyAc',
                status: 'Action',
                phone: phone,
                company: company,
                language: language
            }
            const companyId = `${company} - ${new Date().toISOString()}`
            const companyData = {
                address: address,
                name: company,
                tel: tel,
                status: 'Action'
            }
            createCompanyAc(personalData, uid, companyData, companyId)
            navigate('/login')
        } catch (error: unknown) {
            setErrorMessage((error as Error).message);
            setShowErrorModal(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setShowErrorModal(false);
    };

    return (
        <Container className="mt-5 d-flex flex-column justify-content-center align-items-center">
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="languageSelect">
                    <Form.Label>{chooseLabels.language}</Form.Label>
                    <Form.Control
                        as="select"
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        className="custom-select"
                    >
                        <option value="en">English</option>
                        <option value="zh">中文</option>
                        <option value="th">ไทย</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formEmail">
                    <Form.Label>{chooseLabels.email}</Form.Label>
                    <Form.Control
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formPassword">
                    <Form.Label>{chooseLabels.password}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type='password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group controlId="formConfirmPassword">
                    <Form.Label>{chooseLabels.confirmPassword}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type='password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group controlId="formName">
                    <Form.Label>{chooseLabels.name}</Form.Label>
                    <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formPhone">
                    <Form.Label>{chooseLabels.phone}</Form.Label>
                    <Form.Control
                        type="tel"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formCompany">
                    <Form.Label>{chooseLabels.company}</Form.Label>
                    <Form.Control
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formAddress">
                    <Form.Label>{chooseLabels.address}</Form.Label>
                    <Form.Control
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formTel">
                    <Form.Label>{chooseLabels.tel}</Form.Label>
                    <Form.Control
                        type="tel"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={tel}
                        onChange={(e) => setTel(e.target.value)}
                    />
                </Form.Group>

                <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? "Submitting..." : chooseLabels.submit}
                </Button>
            </Form>

            <Modal show={showErrorModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

