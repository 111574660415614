import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore"
import { create } from "zustand"
import { db, transform } from "../firebase"


interface Acc {
    id?: string
    created_at: string
    email: string
    name: string
    role: string
    status: string
    phone: string
    company?: string
    language: string
}

interface Company {
    id?: string
    address: string | null
    name: string
    tel: string
}

interface CreateAcStore {
    acc: Acc[]
    company: Company[]
    createCompanyAc: (
        acc: Acc, 
        uid: string,
        company:Company,
        companyId: string 
    ) => void
    loadUserItem: (userEmail: string) => void
    loadCompanyItem: () => void
}


export const useCreateAcStore = create<CreateAcStore>((set, get) => ({
    acc: [],
    company: [],
    async createCompanyAc(acc, uid, company, companyId) {
        const store = get()
        await setDoc(doc(db, 'user', uid), acc)
        await setDoc(doc(db, 'company', companyId), company)
        await store.loadUserItem(acc.email)
        await store.loadCompanyItem()
    },
    async loadUserItem(userEmail) {
        const querySnapshot = await query(
            collection(db, 'user'),
            where("email", "==", userEmail)
        )
        const ref = await getDocs(querySnapshot)
        const data = transform<Acc>(ref)
        set({acc: data})
    },
    async loadCompanyItem() {
        const ref = await getDocs(collection(db, 'company'))
        const docs = ref.docs
        const data = docs.map(doc => ({
            id: doc.id, ...doc.data()
        })) as Company[]
        set({company: data})
    }
}))