import { useCallback, useEffect, useState } from "react";
import { bookingLabels } from "./store/language";
import { useAppointmentStore } from "./store/appointment";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { useCreateAcStore } from "./store/create";
import { Form, Button, Container, Image, Alert, Modal } from "react-bootstrap";
import "./BookingPage.css";

export default function BookingPage() {
  const [bookingCompany, setBookingCompany] = useState("");
  const [bookingDate, setBookingDate] = useState("");
  const [bookingTime, setBookingTime] = useState("");
  const [remark, setRemark] = useState("");
  const [labels, setLabels] = useState(bookingLabels["en"]);
  const [image, setImage] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [filteredTime, setFilteredTime] = useState<string[]>([]);
  const [language, setLanguage] = useState("en");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const { addAppointmentItem, loadAppointmentItemByDateAndCompany } =
    useAppointmentStore();
  const { loadCompanyItem, company } = useCreateAcStore();

  useEffect(() => {
    loadCompanyItem();
    setLabels(bookingLabels[language]);
  }, [language, loadCompanyItem]);

  const loadAppointments = useCallback(async () => {
    if (bookingDate && bookingCompany) {
      const data = await loadAppointmentItemByDateAndCompany(
        bookingCompany,
        bookingDate
      );
      const times = [
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
      ];
      const appointmentTimes = data.map((item) => item.bookingTime);
      const filteredTimes = times.filter(
        (time) => !appointmentTimes.includes(time)
      );
      setFilteredTime(filteredTimes);
    }
    console.log("call back function");
  }, [bookingDate, bookingCompany, loadAppointmentItemByDateAndCompany]);

  useEffect(() => {
    if (bookingDate && bookingCompany) {
      loadAppointments();
    }
    console.log("loadBookingTime");
  }, [bookingDate, bookingCompany, loadAppointments]);

  const navigate = useNavigate();

  const uploadImage = async (file: File) => {
    try {
      const storage = getStorage();
      const imageName = `Booking/${name}/${new Date().toISOString()}`;
      const storageRef = ref(storage, imageName);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const selectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImage(URL.createObjectURL(file));
      setImageFile(file);
    }
  };

  const openModal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let imageURL = null;
    if (imageFile) {
      imageURL = await uploadImage(imageFile);
    }
    const data = {
      bookingCompany: bookingCompany,
      bookingDate: bookingDate,
      bookingTime: bookingTime,
      status: "Pending",
      remark: remark,
      photo: imageURL,
      phone: phone,
      created_at: new Date().toLocaleDateString(),
      created_by: name,
      updated_at: null,
      updated_by: null,
    };
    try {
      await addAppointmentItem(data, bookingDate);
      navigate("/first");
    } catch (e) {
      console.log(e);
      setError("Something is wrong, Please try again later");
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  if (!labels) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="d-flex justify-content-center booking">
      <h2 className="text-center mt-4 bookingH2">{labels.label}</h2>
      <Form onSubmit={openModal} className="booking-form">
      <Form.Label>{labels.language}</Form.Label>
        <Container className="language">
          <Form.Select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            className="language-select"
          >
            <option value="en">English</option>
            <option value="zh">中文</option>
            <option value="th">ไทย</option>
          </Form.Select>
        </Container>

        <Form.Group controlId="companySelect">
          <Form.Label>{labels.company}</Form.Label>
          <Form.Control
            as="select"
            value={bookingCompany}
            onChange={(e) => setBookingCompany(e.target.value)}
            required
          >
            <option value="" disabled>
              {labels.selectCompany}
            </option>
            {company.map((item, index) => (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="bookingDate">
          <Form.Label>{labels.bookingDate}</Form.Label>
          <Form.Control
            type="date"
            name="bookingDate"
            value={bookingDate}
            onChange={(e) => setBookingDate(e.target.value)}
            min={new Date().toISOString().split("T")[0]}
            required
          />
        </Form.Group>

        {filteredTime.length > 0 && (
          <Form.Group controlId="bookingTime">
            <Form.Label>{labels.bookingTime}</Form.Label>
            <div className="bookingTime">
              {filteredTime.map((time) => (
                <Form.Check
                  key={time}
                  type="radio"
                  id={`bookingTime-${time}`}
                  label={time}
                  value={time}
                  checked={bookingTime === time}
                  onChange={() => setBookingTime(time)}
                />
              ))}
            </div>
          </Form.Group>
        )}

        <Form.Group controlId="name">
          <Form.Label>{labels.name}</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="phone">
          <Form.Label>{labels.phone}</Form.Label>
          <Form.Control
            type="text"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="remark">
          <Form.Label>{labels.remark}</Form.Label>
          <Form.Control
            type="text"
            name="remark"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="image">
          <Form.Label>{labels.image}</Form.Label>
          <Form.Control
            type="file"
            name="image"
            accept="image/*"
            onChange={selectImage}
          />
          {image && <Image src={image} thumbnail />}
        </Form.Group>

        {error && <Alert variant="danger">{error}</Alert>}

        <Button type="submit" disabled={loading}>
          {loading ? labels.submitting : labels.submit}
        </Button>
      </Form>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{labels.booking}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{labels.confirmBooking}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit} disabled={loading}>
            {labels.submit}
          </Button>
          <Button variant="secondary" onClick={closeModal} disabled={loading}>
            {labels.cancel}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
