import { signOut } from "firebase/auth";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";
import { useUserInfoStore } from "./store/auth";
import { useEffect, useState } from "react";
import { navBarLabels } from "./store/language";
import "./NavbarLink.css";

export default function NavbarLink() {
  const [labels, setLabels] = useState(navBarLabels["en"]);
  const { role, language, loadUserInfo } = useUserInfoStore();

  useEffect(() => {
    loadUserInfo();
    if (language.length > 0) {
      setLabels(navBarLabels[language]);
    }
  }, [language, loadUserInfo]);

  const navigate = useNavigate();

  const closeNavbar = () => {
    const navbarToggle = document.querySelector(
      ".navbar-toggler"
    ) as HTMLElement | null;
    if (navbarToggle && !navbarToggle.classList.contains("collapsed")) {
      navbarToggle.click();
    }
  };
  const goLogin = () => {
    navigate("/Login");
  };

  const goFirst = () => {
    navigate("/first");
  };
  const goBooking = () => {
    navigate("/");
  };
  const goMain = () => {
    navigate("/main");
  };
  const goContact = () => {
    navigate("/contact");
  };
  const logout = async () => {
    try {
      await signOut(auth);
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  if (!labels) {
    return <div>Loading...</div>;
  }

  return (
    <Navbar
      expand="lg"
      variant="dark"
      className="navbar-custom"
      onClick={closeNavbar}
    >
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {role === "CompanyAc" ? (
              <>
                <Nav.Link onClick={goFirst} className="nav-item-custom">
                  {labels.first}
                </Nav.Link>
                <Nav.Link onClick={goMain} className="nav-item-custom">
                  {labels.checkCompanyAppointments}
                </Nav.Link>
                <Nav.Link onClick={goContact} className="nav-item-custom">
                  {labels.contactUs}
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link onClick={goBooking} className="nav-item-custom">
                  {labels.makeAppointment}
                </Nav.Link>
                <Nav.Link onClick={goContact} className="nav-item-custom">
                  {labels.contactUs}
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Brand className="navbar-brand-custom">
          <img
            src={`${process.env.PUBLIC_URL}/logo.jpg`}
            className="d-inline-block align-top logo-custom"
            alt="Logo"
          />
        </Navbar.Brand>
        {role === "CompanyAc" ? (
          <Button onClick={logout} className="button">
            Logout
          </Button>
        ) : (
          <Button onClick={goLogin} className="button">
            Login
          </Button>
        )}
      </Container>
    </Navbar>
  );
}
