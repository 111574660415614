import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "./store/auth";
import { createAccLabels } from "./store/language";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";

export default function Login() {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [language, setLanguage] = useState("en");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { login } = useAuthStore();

  const labels = createAccLabels[language];
  const navigate = useNavigate();

  const loginSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const success = await login(loginEmail.trim().toLowerCase(), loginPassword);
    if (success) {
      console.log("Login Success");
      navigate("/first");
    } else {
      const { error } = useAuthStore.getState();
      setErrorMessage(error);
      setShowErrorModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowErrorModal(false);
  };

  return (
    <Container className="Login d-flex flex-column justify-content-center align-items-center">
      <Row className="justify-content-center w-100">
        <Col xs="auto">
          <img
            src={`${process.env.PUBLIC_URL}/logo.jpg`}
            className="d-inline-block align-top logo"
            alt="Logo"
          />
        </Col>
      </Row>

      <Container className="language">
        <Form.Select
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          className="language-select"
        >
          <option value="en">English</option>
          <option value="zh">中文</option>
          <option value="th">ไทย</option>
        </Form.Select>
      </Container>

      <Row className="justify-content-center rowLoginPage">
        <Form onSubmit={loginSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>{labels.email}</Form.Label>
            <Form.Control
              type="text"
              value={loginEmail}
              onChange={(e) => setLoginEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword" className="mt-3">
            <Form.Label>{labels.password}</Form.Label>
            <Form.Control
              type="password"
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-4 submitButton">
            {labels.submit}
          </Button>
        </Form>

        <div className="d-flex flex-column mt-3">
          <Button
            onClick={() => navigate("/createCompany")}
            className="w-100 secondary"
          >
            {labels.createCompanyAccount}
          </Button>
        </div>
      </Row>

      <Modal show={showErrorModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
