import { useUserInfoStore } from "../store/auth"
import { useEffect, useState } from "react"
import { firstPageLabels } from "../store/language"
import { useAppointmentStore } from "../store/appointment"
import { Button, Row, Col, Card, Image, Container, Modal } from 'react-bootstrap';
import './FirstPage.css'

export default function FirstPage() {
    const {
        loadUserInfo,
        role,
        language,
        name,
        company
    } = useUserInfoStore()
    const {
        companyAppointment,
        loadAppointmentItemByName,
        loadAppointmentItemByCompany,
        updateAppointmentItem,
    } = useAppointmentStore()

    const [showItem, setShowItem] = useState('Pending')
    const [labels, setLabels] = useState(firstPageLabels['en'])
    const [showModal, setShowModal] = useState(false);
    const [confirmItemId, setConfirmItemId] = useState<string>('');
    const [rejectItemId, setRejectItemId] = useState<string>('');
    const [type, setType] = useState('');
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        loadUserInfo()
        loadAppointmentItemByName(name)
        setLabels(firstPageLabels[language])
        if (company) {
            loadAppointmentItemByCompany(company)
        }
        console.log('FirstPage')
    }, [language, loadUserInfo, loadAppointmentItemByName, setLabels, name, company, loadAppointmentItemByCompany])

    const forCompany = companyAppointment.filter(item => item.status === showItem)

    const openModal = (id: string, type: string) => {
        setShowModal(true);
        setType(type)
        if (type === 'Confirmed') {
            setConfirmItemId(id);
        } else {
            setRejectItemId(id);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setConfirmItemId('');
        setRejectItemId('');
        setType('')
    };

    const confirmOrRejectItem = async () => {
        setLoading(true)
        const data = {
            status: type
        }
        const itemId = type === 'Confirmed' ? confirmItemId : rejectItemId;
        await updateAppointmentItem(itemId, data);
        loadAppointmentItemByCompany(company);
        setLoading(false)
        closeModal();
    }

    if (!labels) {
        return (<div>
            Loading...
            </div>);
    }

    return (
        <Container>
            <Container>
                <Row className="mb-3">
                    <Col>
                        <Button variant="outline-primary" onClick={() => setShowItem('Pending')}>{labels.pendingAppointment}</Button>
                    </Col>
                    <Col>
                        <Button variant="outline-success" onClick={() => setShowItem('Confirmed')}>{labels.confirmedAppointment}</Button>
                    </Col>
                    <Col>
                        <Button variant="outline-danger" onClick={() => setShowItem('Rejected')}>{labels.rejectedAppointment}</Button>
                    </Col>
                </Row>
            </Container>

            <Container className="cardBody">
                <Col lg={8}>
                    {role === 'CompanyAc' &&
                        <Container>
                            {forCompany.length > 0 ? (forCompany.map((item, index) =>
                                <Card key={index}>
                                    <Card.Body>
                                        <Container className="dataWithImage">
                                            <Container className="data">
                                                <Card.Title>{labels.name}: {item.created_by}</Card.Title>
                                                <Card.Text>{labels.phone}: {item.phone}</Card.Text>
                                                <Card.Text>{labels.remark}: {item.remark}</Card.Text>
                                                <Card.Text>{labels.bookingDate}: {item.bookingDate} - {item.bookingTime}</Card.Text>
                                                <Card.Text>{labels.status}: {item.status}</Card.Text>
                                            </Container>
                                            {item.photo ? (
                                                <Image src={item.photo} thumbnail />
                                            ) : (
                                                <Image src={`${process.env.PUBLIC_URL}/noImage.jpg`} thumbnail />
                                            )}
                                        </Container>
                                        {
                                            item.status === 'Pending' && role === 'CompanyAc' &&
                                            <Container className="card-button-group">
                                                <Button variant="danger" onClick={() => openModal(item.id!, 'Rejected')}>{labels.reject}</Button>
                                                <Button variant="success" onClick={() => openModal(item.id!, 'Confirmed')}>{labels.confirm}</Button>
                                            </Container>
                                        }
                                    </Card.Body>
                                </Card>
                            )) : (
                                <Col>
                                    <Container>{labels.noData}</Container>
                                </Col>
                            )}
                        </Container>
                    }
                </Col>
            </Container>

            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    {
                        type === 'Confirmed' ?
                            <Modal.Title>{labels.confirmAppointment}</Modal.Title> :
                            <Modal.Title>{labels.rejectAppointment}</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    {
                        type === 'Confirmed' ?
                            <p>{labels.confirmQuestion}</p> :
                            <p>{labels.rejectQuestion}</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal} disabled={loading}>
                        {labels.cancel}
                    </Button>
                    <Button variant="primary" onClick={confirmOrRejectItem} disabled={loading}>
                        {type === "Confirmed" ? labels.confirm : labels.reject}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

