interface CreateAccLabels {
    [key: string]: {
        language: string;
        email: string;
        password: string;
        confirmPassword: string;
        name: string;
        submit: string;
        company: string;
        address: string;
        tel: string;
        phone: string
        createClientAccount: string;
        createCompanyAccount: string;
    };
}

export const createAccLabels: CreateAccLabels = {
    en: {
        language: 'Select Language',
        email: 'Login Email',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        name: 'Name',
        submit: 'Submit',
        company: 'Company Name',
        address: 'Company Address',
        tel: 'Company Tel.',
        phone: 'Phone Number',
        createClientAccount: 'Create Client Account',
        createCompanyAccount: 'Create Company Account',
    },
    zh: {
        language: '選擇語言',
        email: '登入電子郵件',
        password: '密碼',
        confirmPassword: '確定密碼',
        name: '名字',
        submit: '提交',
        company: '公司名稱',
        address: '公司地址',
        tel: '公司電話',
        phone: '電話號碼',
        createClientAccount: '創建顧客帳號',
        createCompanyAccount: '創建公司帳號',
    },
    th: {
        language: 'เลือกภาษา',
        email: 'อีเมลเข้าสู่ระบบ',
        password: 'รหัสผ่าน',
        confirmPassword: 'ยืนยันรหัสผ่าน',
        name: 'ชื่อ',
        submit: 'ส่ง',
        company: 'ชื่อบริษัท',
        address: 'ที่อยู่บริษัท',
        tel: 'โทรศัพท์บริษัท',
        phone: 'หมายเลขโทรศัพท์',
        createClientAccount: 'สร้างบัญชีลูกค้า',
        createCompanyAccount: 'สร้างบัญชีบริษัท',
    },
};

interface NavBarLabels {
    [key: string]: {
        first: string;
        checkMyAppointment: string;
        makeAppointment: string;
        checkNewAppointment: string;
        checkCompanyAppointments: string;
        contactUs: string;
        logout: string;
    };
}

export const navBarLabels: NavBarLabels = {
    en: {
        first: 'Appointment',
        checkMyAppointment: 'Check My Appointment Status',
        makeAppointment: 'Make an Appointment',
        checkNewAppointment: 'Check New Appointment',
        checkCompanyAppointments: 'Check Company Appointments',
        contactUs: 'Contact Us',
        logout: 'Logout',
    },
    zh: {
        first: '查看預約',
        checkMyAppointment: '查看我的預約狀態',
        makeAppointment: '預約服務',
        checkNewAppointment: '查看新預約',
        checkCompanyAppointments: '查看公司預約',
        contactUs: '聯絡我們',
        logout: '登出',
    },
    th: {
        first: 'ตรวจสอบการนัดหมาย',
        checkMyAppointment: 'ตรวจสอบสถานะการนัดหมายของฉัน',
        makeAppointment: 'นัดหมาย',
        checkNewAppointment: 'ตรวจสอบการนัดหมายใหม่',
        checkCompanyAppointments: 'ตรวจสอบการนัดหมายของบริษัท',
        contactUs: 'ติดต่อเรา',
        logout: 'ออกจากระบบ',
    }
};

interface FirstPageLabels {
    [key: string]: {
        pendingAppointment: string;
        confirmedAppointment: string;
        rejectedAppointment: string;
        name: string;
        phone: string;
        remark: string;
        applyDate: string;
        bookingDate: string;
        status: string;
        noData: string;
        confirm: string;
        reject: string;
        confirmAppointment: string;
        rejectAppointment: string;
        confirmQuestion: string;
        rejectQuestion: string;
        cancel: string;
    };
}


export const firstPageLabels: FirstPageLabels = {
    en: {
        pendingAppointment: "Pending Appointment",
        confirmedAppointment: "Confirmed Appointment",
        rejectedAppointment: "Rejected Appointment",
        name: "Name",
        phone: "Phone",
        remark: "Remark",
        applyDate: "Apply Date",
        bookingDate: "Booking Date",
        status: "Status",
        noData: "No Data",
        confirm: "Confirm",
        reject: "Reject",
        confirmAppointment: "Confirm Appointment",
        rejectAppointment: "Reject Appointment",
        confirmQuestion: "Do you want to confirm this appointment?",
        rejectQuestion: "Do you want to reject this appointment?",
        cancel: "Cancel"
    },
    zh: {
        pendingAppointment: "待處理的預約",
        confirmedAppointment: "已確認的預約",
        rejectedAppointment: "已拒絕的預約",
        name: "姓名",
        phone: "電話",
        remark: "備註",
        applyDate: "申請日期",
        bookingDate: "預訂日期",
        status: "狀態",
        noData: "無數據",
        confirm: "確認",
        reject: "拒絕",
        confirmAppointment: "確認預約",
        rejectAppointment: "拒絕預約",
        confirmQuestion: "您要確認此預約嗎？",
        rejectQuestion: "您要拒絕此預約嗎？",
        cancel: "取消"
    },
    th: {
        pendingAppointment: "การนัดหมายที่รอดำเนินการ",
        confirmedAppointment: "การนัดหมายที่ยืนยันแล้ว",
        rejectedAppointment: "การนัดหมายที่ถูกปฏิเสธ",
        name: "ชื่อ",
        phone: "โทรศัพท์",
        remark: "หมายเหตุ",
        applyDate: "วันที่สมัคร",
        bookingDate: "วันที่จอง",
        status: "สถานะ",
        noData: "ไม่มีข้อมูล",
        confirm: "ยืนยัน",
        reject: "ปฏิเสธ",
        confirmAppointment: "ยืนยันการนัดหมาย",
        rejectAppointment: "ปฏิเสธการนัดหมาย",
        confirmQuestion: "คุณต้องการยืนยันการนัดหมายนี้หรือไม่?",
        rejectQuestion: "คุณต้องการปฏิเสธการนัดหมายนี้หรือไม่?",
        cancel: "ยกเลิก"
    }
};   

interface BookingLabels {
    [key: string]: {
        label: string,
        name: string,
        phone: string,
        company: string;
        bookingDate: string;
        bookingTime: string;
        remark: string;
        image: string;
        submit: string;
        submitting: string;
        language: string;
        selectCompany: string;
        booking: string
        confirmBooking: string;
        cancel: string;
    }
}

export const bookingLabels: BookingLabels = {
    en: {
        label: 'Make an Appointment',
        language: 'Select Language',
        name: 'Name',
        phone: 'Phone Number',
        company: "Company",
        bookingDate: "Booking Date",
        bookingTime: "Booking Time",
        remark: "Remark",
        image: "Image",
        submit: "Submit",
        submitting: "Submitting...",
        selectCompany: "Select the company",
        confirmBooking: "Are you want to submit this booking?",
        booking: 'Make Appointment',
        cancel: "Cancel",
    },
    zh: {
        label: '預約服務',
        language: '選擇語言',
        name: '名字',
        phone: '電話號碼',
        company: "公司",
        bookingDate: "預訂日期",
        bookingTime: "預訂時間",
        remark: "備註",
        image: "圖片",
        submit: "提交",
        submitting: "提交中...",
        selectCompany: "選擇公司",
        confirmBooking: "您確定要提交此預約嗎？",
        booking: "預約時間",
        cancel: "取消",
    },
    th: {
        label: 'นัดหมาย',
        language: 'เลือกภาษา',
        name: 'ชื่อ',
        phone: 'หมายเลขโทรศัพท์',
        company: "บริษัท",
        bookingDate: "วันที่จอง",
        bookingTime: "เวลาที่จอง",
        remark: "หมายเหตุ",
        image: "รูปภาพ",
        submit: "ส่ง",
        submitting: "กำลังส่ง...",
        selectCompany: "เลือกบริษัท",
        confirmBooking: "คุณแน่ใจหรือไม่ว่าต้องการส่งการจองนี้?",
        booking: "นัดหมาย",
        cancel: "ยกเลิก",
    }
};


interface ContactUsLabels {
    [key: string]: {
        contactUs: string;
        yourName: string;
        yourContact: string;
        yourContent: string;
        submit: string;
        language: string;
    };
}

export const contactUsLabels: ContactUsLabels = {
    en: {
        language: 'Select Language',
        contactUs: 'Contact Us',
        yourName: 'Your Name',
        yourContact: 'Your Phone or Reply Email',
        yourContent: 'Your Content',
        submit: 'Submit',
    },
    zh: {
        language: '選擇語言',
        contactUs: '聯絡我們',
        yourName: '你的名字',
        yourContact: '你的電話或回覆電郵',
        yourContent: '你的內容',
        submit: '提交',
    },
    th: {
        language: 'เลือกภาษา',
        contactUs: 'ติดต่อเรา',
        yourName: 'ชื่อของคุณ',
        yourContact: 'โทรศัพท์หรืออีเมลตอบกลับของคุณ',
        yourContent: 'เนื้อหาของคุณ',
        submit: 'ส่ง',
    },
};
