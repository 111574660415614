import { addDoc, collection, doc, documentId, getDocs, query, updateDoc, where } from "firebase/firestore"
import { create } from "zustand"
import { db } from "../firebase"

export interface Appointment {
    id?: string
    bookingCompany: string
    type?: string
    bookingDate: string
    bookingTime: string
    status: string
    remark: string
    photo: string | null
    phone: string
    created_at: string
    created_by: string
    updated_at: string | null
    updated_by: string | null
}

interface AppointmentStore {
    appointment: Appointment[]
    appointmentById: Appointment[]
    companyAppointment: Appointment[]
    addAppointmentItem: (appointment: Appointment, date: string) => void
    loadAppointmentItemByDate: (date: string) => void
    loadAppointmentItemById: (id: string) => void
    loadAppointmentItemByCompany: (company: string) => void
    loadAppointmentItemByName: (name: string) => void
    loadAppointmentItemByDateAndCompany: (company: string, date: string) => Promise<Appointment[]>
    updateAppointmentItem: (id: string, data: Partial<Appointment>) => void
}

export const useAppointmentStore = create<AppointmentStore>((set, get) => ({
    appointment: [],
    appointmentById: [],
    companyAppointment: [],
    async addAppointmentItem(appointment, date) {
        const store = get()
        await addDoc(collection(db, 'appointment'), appointment)
        await store.loadAppointmentItemByDate(date)
    },
    async loadAppointmentItemByDate(date) {
        const querySnapshot = await query(
            collection(db, 'appointment'),
            where("bookingDate", "==", date)
        )
        const ref = await getDocs(querySnapshot)
        const docs = ref.docs
        const data = docs.map(doc => ({
            id: doc.id, ...doc.data()
        })) as Appointment[]
        set({appointment: data})
    },
    async loadAppointmentItemById(id) {
        const querySnapshot = await query(
            collection(db, 'appointment'),
            where(documentId(), "==", id)
        )
        const ref = await getDocs(querySnapshot)
        const docs = ref.docs
        const data = docs.map(doc => ({
            id: doc.id, ...doc.data()
        })) as Appointment[]
        set({appointmentById: data})
    },
    async loadAppointmentItemByCompany(company) {
        const querySnapshot = await query(
            collection(db, 'appointment'),
            where("bookingCompany", "==", company)
        )
        const ref = await getDocs(querySnapshot)
        const docs = ref.docs
        const data = docs.map(doc => ({
            id: doc.id, ...doc.data()
        })) as Appointment[]
        set({companyAppointment: data})
    },
    async loadAppointmentItemByName(name) {
        const querySnapshot = await query(
            collection(db, 'appointment'),
            where("created_by", "==", name)
        )
        const ref = await getDocs(querySnapshot)
        const docs = ref.docs
        const data = docs.map(doc => ({
            id: doc.id, ...doc.data()
        })) as Appointment[]
        set({appointment: data})
    },
    async loadAppointmentItemByDateAndCompany(company, date) {
        const querySnapshot = await query(
            collection(db, 'appointment'),
            where("bookingCompany", "==", company),
            where("bookingDate", "==", date)
        )
        const ref = await getDocs(querySnapshot)
        const docs = ref.docs
        const data = docs.map(doc => ({
            id: doc.id, ...doc.data()
        })) as Appointment[]
        // set({appointments: data})
        return data
    },
    async updateAppointmentItem(id, data) {
        const table = doc(db, 'appointment', id)
        await updateDoc(table, data)
    },
}))